import React from 'react';
import { NavLink } from 'react-router-dom'
import styles from './index.module.css';
import { Menu } from 'antd';
import { UserOutlined, ProfileOutlined, BookOutlined } from '@ant-design/icons';
import loginService from './../../../services/login';

const LeftMenu = () => {
  const role = loginService.getRole();
  return (
    <Menu
      theme="dark"
      className={styles.sider}
      mode={'inline'}
    >
      <Menu.Item key="/shipment" icon={<ProfileOutlined />}>
        <NavLink to="/shipment">运单管理</NavLink>
      </Menu.Item>
      {
        role === 99 || role === 0 || role === 1 ? <Menu.Item key="/activity" icon={<ProfileOutlined />}>
          <NavLink to="/activity">运输详情</NavLink>
        </Menu.Item> : null
      }

      <Menu.Item key="/dict" icon={<BookOutlined />}>
        <NavLink to="/dict">字典</NavLink>
      </Menu.Item>

      {
        role === 99 || role === 0 || role === 1 ? <>
          <Menu.Item key="/team" icon={<BookOutlined />}>
            <NavLink to="/team">物流公司</NavLink>
          </Menu.Item>
          <Menu.Item key="/userinfo" icon={<UserOutlined />}>
            <NavLink to="/userinfo">用户管理</NavLink>
          </Menu.Item>
        </> : null
      }

    </Menu>
  )
}

export default React.memo(LeftMenu);
