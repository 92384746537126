// import Cookies from 'universal-cookie';

// const cookies = new Cookies();


const class2type = {};
const { toString } = class2type;
'Boolean Number String Function Array Date RegExp Object Error'.split(' ').forEach((name) => {
  class2type[`[object ${name}]`] = name.toLowerCase();
});
/**
 * 对象类型
 * @param {object} obj
 */
export function objType(obj) {
  if (obj == null) {
    return `${obj}`;
  }
  return typeof obj === 'object' || typeof obj === 'function' ? class2type[toString.call(obj)] || 'object' : typeof obj;
}
/**
 * 是否数组
 * @param {object} obj 需要判断的对象
 * @return {boolean} 返回 true 或 false
 */
export function isArray(obj) {
  return objType(obj) === 'array';
}
/**
 * 将简单结构数据转换成树状结构
 * @param {object} setting 设置节点的 idKey\pIdKey\children
 * @param {array} sNodes 数据数组
 * @return {array} 树状解构数据
 */
export function transformToTreeFormat(setting, sNodes) {
  setting = setting || {};
  let i;
  let l;
  const key = setting.idKey || 'id'; // 当前节点的唯一key名
  const parentKey = setting.pIdKey || 'pid'; // 指向的父节点唯一key名
  const childKey = setting.children || 'children'; // 子元素的key名

  if (!key || key === '' || !sNodes) return [];

  if (isArray(sNodes)) {
    const r = [];
    const tmpMap = [];
    for (i = 0, l = sNodes.length; i < l; i += 1) {
      tmpMap[sNodes[i][key]] = sNodes[i];
    }
    for (i = 0, l = sNodes.length; i < l; i += 1) {
      if (tmpMap[sNodes[i][parentKey]] && sNodes[i][key] !== sNodes[i][parentKey]) {
        if (!tmpMap[sNodes[i][parentKey]][childKey]) {
          tmpMap[sNodes[i][parentKey]][childKey] = [];
        }
        tmpMap[sNodes[i][parentKey]][childKey].push(sNodes[i]);
      } else {
        r.push(sNodes[i]);
      }
    }
    return r;
  }

  return [sNodes];
}

/**
 * int 属性值排序
 * @param {object} o1
 * @param {object} o2
 * @param {string} key
 */
export function orderByInt(o1, o2, key) {
  const v1 = o1[key] || 0;
  const v2 = o2[key] || 0;

  if (parseInt(v1, 10) >= parseInt(v2, 10)) {
    return 1;
  }

  return -1;
}
/**
 * 检查用户是否登陆
 */
export function checkUL() {
  return true;
  // const token = cookies.get('token');
  // const uid = cookies.get('uid');

  // return token != null && uid != null;
}


export function isEmptyObject(e) {
  if (e === undefined || e === null) {
    return true;
  }
  for (const t in e) {
    return false;
  }

  return true;
}

export function strIsEmpty(v) {
  return v === undefined || v == null || v === '';
}

export function complexUrlParams(resultObj, key, obj) {
  if (!resultObj) {
    resultObj = {};
  }
  if (typeof obj !== 'object') {
    resultObj[key] = obj;
    return resultObj;
  }

  for (const subkey in obj) {
    complexUrlParams(resultObj, `${key}[${subkey}]`, obj[subkey]);
  }
  return resultObj;
}

export function arr2map(arrData) {
  if (!Array.isArray(arrData)) {
    return null;
  }
  const ret = {};
  for (const str of arrData) {
    ret[str] = true;
  }
  return ret;
}

export function map2arr(mapData) {
  if (!mapData) {
    return null;
  }

  const ret = [];
  for (const key in mapData) {
    if (mapData[key]) {
      ret.push(key);
    }
  }
  return ret;
}

export function isDebug() {
  return window.location.host.indexOf('dev') > 0 || window.location.host.indexOf('localhost') >= 0;
}


export function normalizeSearchTmRange(tm, type) {
  if (!tm || !tm[0] || !tm[1]) {
    return undefined;
  }

  if (type === 'd') {
    return [
      tm[0].format('YYYY-MM-DD 00:00:00'),
      tm[1].format('YYYY-MM-DD 00:00:00'),
    ];
  }
  if (type === 'm') {
    return [
      tm[0].format('YYYY-MM-01 00:00:00'),
      tm[1].format('YYYY-MM-01 00:00:00'),
    ];
  }
  if (type === 't') {
    return [
      tm[0].format('YYYY-MM-01 00:00:00'),
      tm[1].format('YYYY-MM-21 00:00:00'),
    ];
  }
  if (type === 'y') {
    return [
      tm[0].format('YYYY-01-01 00:00:00'),
      tm[1].format('YYYY-01-01 00:00:00'),
    ];
  }
  if (type === 'h') {
    return [
      tm[0].format('YYYY-MM-DD HH:00:00'),
      tm[1].format('YYYY-MM-DD HH:00:00'),
    ];
  }

  return [
    tm[0].format('YYYY-MM-DD HH:mm:00'),
    tm[1].format('YYYY-MM-DD HH:mm:00'),
  ];
}

export const MomentTypeFromTmType = {
  h: 'hour',
  m: 'month',
  d: 'day',
}

export function sortWsTree(roots) {
  const result = roots.sort((a, b) => {
    const lenA = a.children ? a.children.length : 0;
    const lenB = b.children ? b.children.length : 0;
    return lenB - lenA;
  });

  for (const elem of roots) {
    elem.children = sortWsTree(elem.children);
  }

  return result;
}

export function strip(num, precision = 12) {
  return +parseFloat(num.toPrecision(precision));
}