import JsonApi from '../../utils/jsonapi';
import { message } from 'antd';

const SORT_ORDER_MAP = {
  ascend: 'asc',
  descend: 'desc',
};

/**
 * 增删改查基础类
 */
export default class CrudSerivce extends JsonApi {
  constructor(props) {
    super(props);

    this.props = {
      apiurl: props.apiurl || {},
    };
  }

  /**
   * 分页查询
   * 把查询参数直接放在url上
   */
  async paginate(params = {}) {
    const { pageNumber, pageSize, sort, search = {} } = params;
    const url = this.props.apiurl.find;

    // 处理项目的逻辑删除
    if (search && typeof search.del === 'boolean') {
      if (search.del) {
        search.del = 1;
      } else {
        delete search.del;
      }
    }

    const reqParams = {
      ...search,
      page: pageNumber,
      size: pageSize,
    };
    if (sort && sort.field) {
      reqParams.sort_field = sort.field;
      reqParams.sort_order = SORT_ORDER_MAP[sort.order] || 'asc';
    }

    const result = await this.httpget(url, reqParams);
    if (result && result.data && result.code === 200) {
      const { data } = result;
      return data;
    } else {
      let msg = result ? result.msg : '请求失败';
      if (result && 'validerr' in result) {
        msg += '。' + Object.values(result.validerr).join(';');
      }

      message.error(msg);
    }

    return { list: [], totalRow: 0 };
  }

  /**
   * 新增
   * @param {*} params
   */
  async save(params) {
    const { data, msg } = await this.httppost(this.props.apiurl.save, params) || {};
    if (!data && msg) {
      if (typeof msg === 'string') {
        message.error(msg);
      } else {
        message.error(JSON.stringify(msg));
      }
    }

    return data;
  }

  /**
   * 修改
   * @param {*} params
   */
  async edit(params) {
    const { data } = await this.httppost(this.props.apiurl.edit, params) || {};

    return data;
  }

  /**
   * 根据对象删除
   * @param {object} pval 需要删除的主键对象， { id: 1 }, 复合主键 { id1: 1, id2: 2 }
   */
  async del(params) {
    const { data } = await this.httppost(this.props.apiurl.del, params) || {};

    return data;
  }

  async restore(params) {
    const { data } = await this.httppost(this.props.apiurl.restore, params) || {};

    return data;
  }

  /**
   * 数组列表
   */
  async list(search) {
    // 处理项目的逻辑删除
    if (search && typeof search.del === 'boolean') {
      if (search.del) {
        search.del = 1;
      } else {
        delete search.del;
      }
    }

    const { data } = await this.httpget(this.props.apiurl.list, search) || {};
    return data || [];
  }
}
