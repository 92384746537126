import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import Home from './routers/home/index'
import Login from './routers/login/index'
import NotFound from './routers/notfound/index'
import AuthLayout from './routers/layouts/Auth';
import DashboardLayout from './routers/layouts/Dashboard';

const routes = [

    { path: '/', exact: true, component: Home },
    {
        path: '/auth', component: AuthLayout, routes: [
            { path: '/auth/login', exact: true, component: Login },
            { path: '/404', component: NotFound },
            { component: () => <Redirect to="/404" /> }
        ]
    },
    {
        route: '*', component: DashboardLayout, routes: [
            { path: '/shipment', component: lazy(() => import('./routers/shipment/index')) },
            { path: '/activity', component: lazy(() => import('./routers/activity/index')) },
            { path: '/dict', component: lazy(() => import('./routers/dict/index')) },
            { path: '/team', component: lazy(() => import('./routers/team/index')) },
            { path: '/userinfo', component: lazy(() => import('./routers/userinfo/index')) },
            { path: '/404', component: NotFound },
            { component: () => <Redirect to="/404" /> }
        ]
    },
    { path: '/404', component: NotFound },
    { component: () => <Redirect to="/404" /> }
];

export default routes;