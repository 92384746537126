import apiurl from './apiurl';
import CrudSerivce from '../components/Crud/CrudSerivce';

class ShipmentService extends CrudSerivce {
  async get(id) {
    const ret = await this.httpget(apiurl.shipment.get, { id });
    return ret;
  }

  async receive({ id }) {
    const { code } = await this.httppost(apiurl.shipment.receive, { id });
    return code === 200;
  }

  async batchreceive({ ids }) {
    const { code } = await this.httppost(apiurl.shipment.batchreceive, { ids });
    return code === 200;
  }

  skpdf(record) {
    this.download(apiurl.shipment.skpdf, { warehouse_id: record.id });
  }

  fbapdf(record) {
    this.download(apiurl.shipment.fbapdf, { warehouse_id: record.id });
  }

  exportFp(record) {
    this.download(apiurl.shipment.exportFp, { id: record.id, type: record.type });
  }

  exportKds(record) {
    this.download(apiurl.shipment.exportKds, { id: record.id, type: record.type });
  }

  exportOcs(record) {
    this.download(apiurl.shipment.exportOcs, { id: record.id, type: record.type });
  }

  export(record) {
    this.download(apiurl.shipment.export, record);
  }
}

const service = new ShipmentService({ apiurl: { ...apiurl.shipment } });
export default service;
