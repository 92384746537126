import React from 'react';

const Copyright = function () {
  return (
    <div>
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19126656号</a> Copyright ©2014-{new Date().getFullYear()} <br/>KDS.
    </div>
  );
};

export default Copyright;
