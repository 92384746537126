import React, { useState } from 'react';
import { Form, Button, Input } from 'antd';
import { formItemLayout, tailItemLayout } from '../../../components/Form/formstyle';


const ModPwForm = ({ changePw }) => {
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [form] = Form.useForm();

  const onFinish = () => {
    const values = form.getFieldValue();
    changePw(values);
  }

  const handleConfirmBlur = (e) => {
    const value = e.target.value;
    setConfirmDirty(confirmDirty || !!value);
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldsValue()['newpd']) {
      callback('密码不一致!');
    } else {
      callback();
    }
  }

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  return (
    <Form form={form} {...formItemLayout} onFinish={onFinish}>
      <Form.Item
        name="oldpwd"
        label="旧密码"
        rules={[{ required: true, message: '请输入旧密码!' }]}
      >
        <Input type="password" style={{ width: 200 }} />
      </Form.Item>
      <Form.Item
        name="newpwd"
        label="新密码"
        rules={[
          { required: true, message: '请输入新密码!', },
          { validator: validateToNextPassword, }
        ]}
      >
        <Input type="password" style={{ width: 200 }} />
      </Form.Item>
      <Form.Item
        label="确认密码"
        name="confirm"
        rules={[
          { required: true, message: '请确认密码!', },
          { validator: compareToFirstPassword, }
        ]}
      >
        <Input type="password" style={{ width: 200 }} onBlur={handleConfirmBlur} />
      </Form.Item>
      <Form.Item {...tailItemLayout}>
        <Button type="primary" htmlType="submit" onClick={() => onFinish()}>修改</Button>
      </Form.Item>
    </Form>
  );
}

export default ModPwForm;
