import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import { Layout } from 'antd';
import styles from './index.module.css';
import LeftMenu from './LeftMenu';
import HeaderUser from './HeaderUser';
import Loading from './../../../components/common/Loading';

const { Header, Content, Sider } = Layout;


const Dashboard = props => {
  const { route } = props;

  return (
    <Layout className="root">
      <Header className={styles.header}>
        <div className={styles.logo}>KDS后台管理系统</div>
        <HeaderUser />
      </Header>

      <Layout>
        <Sider breakpoint="xl" width={200} className={styles.leftsider}>
          <LeftMenu />
        </Sider>
        <Layout>
          <Content className={styles.content}>
            <Suspense fallback={<Loading />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
