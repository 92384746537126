import React from 'react';
import { Modal, message } from 'antd';
import Form from './Form';
import loginService from './../../../services/login';


export default class ModPwModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  show = () => {
    this.setState({ open: true });
  }

  handleSubmit = async (params) => {
    loginService.changePw(params).then((result) => {
      if (result) {
        message.success('修改成功');
        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
      } else {
        message.error('修改失败');
      }
    });

  }

  render() {
    const { open } = this.state;
    if (!open) {
      return null;
    }

    return (
      <Modal
        maskClosable={false}
        title="修改密码"
        width="400px"
        visible
        onCancel={() => this.setState({ open: false })}
        footer={null}
      >
        <Form
          changePw={this.handleSubmit}
        />
      </Modal>
    );
  }
}
