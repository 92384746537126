import React, { Component } from 'react';
import { Button, Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import service from '../../services/login';
import styles from './index.module.css';
import logoImg from './../../assets/logo.svg';
import Copyright from './../../components/CopyRight';

const FormItem = Form.Item;

export default class Login extends Component {
    onFinish = async (values) => {
        const result = await service.auth(values);

        if (result) {
            this.props.history.push('/shipment');
        }
    }

    render() {
        const { onFinish } = this;

        return (
            <div className={styles.container}>
                <div className={styles.top}>
                    <div className={styles.header}>
                        <img alt="" className={styles.logo} src={logoImg} />
                        <span className={styles.title}>KDS后台管理系统</span>
                    </div>
                </div>
                <div className={styles.main}>
                    <Form onFinish={onFinish} ref={this.formRef}>
                        <FormItem rules={[{ required: true, message: '必填' }]} name="uname">
                            <Input allowClear size="large" onPressEnter={onFinish} placeholder="用户名" prefix={<UserOutlined />} autoComplete="true" />
                        </FormItem>
                        <FormItem rules={[{ required: true, message: '必填' }]} name="upasswd">
                            <Input.Password placeholder="密码" allowClear size="large" prefix={<LockOutlined />} autoComplete="true" />
                        </FormItem>
                        <FormItem>
                            <Button type="primary" htmlType="submit" size="large" className={styles.submit}>登陆</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={styles.foot}>
                    <div className={styles.copy}>
                        <Copyright />
                    </div>
                </div>
            </div>
        );
    }
}
