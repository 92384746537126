import * as tools from './../utils/tools';
import { message } from 'antd';


function getErrCode(msg) {
  const ERROR_CODE = {
    'Internal Server Error': '后端接口错误',
    'Server Error': '后端接口错误',
    'Not Found': '接口路径错误',
    'Gateway Timeout': '网络超时',
  };

  if (msg in ERROR_CODE) {
    return ERROR_CODE[msg];
  }

  return msg;
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function request(url, options) {
  const opt = { ...options };
  opt.headers = opt.headers || {};
  opt.headers.Accept = 'application/json';
  // opt.credentials = opt.credentials || 'include';
  
  return fetch(url, opt)
    .then(checkStatus)
    .then(response => response.json())
    .then(data => ({ data })) // { data: 接口实际返回的数据 }
    .catch(err => ({ err })); // { err: 错误信息 }
}


export default class JsonApi {
  /**
   * 调用api
   * @param {string} url
   * @param {object} options
   */
  async send(url, options) {
    try {
      const res = await request(url, options);

      if (!('data' in res) && 'err' in res) {
        message.error(getErrCode(res.err.message));
        return null;
      }

      const code = res.data.code;
      if (code === 401) {
        message.error(res.data.msg);
        window.location.href = '/auth/login';
      } else if (code === 404) {
        // message.error(`未找到资源: ${url}`);
      }

      return res.data;
    } catch (e) {
      message.error(e);
    }

    return null;
  }

  httpget(url, data = {}) {
    const params = [];
    for (const k in data) {
      const v = data[k];
      if (tools.strIsEmpty(v)) { continue; }

      params.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
    const token = this.getToken();
    if (token) {
      params.push(`token=${token}`);
    }
    params.push(`_=${Date.now()}`);
    const strparams = params.join('&');
    if (params.length > 0) {
      if (url.indexOf('?') > 0) {
        url += `&${strparams}`;
      } else {
        url += `?${strparams}`;
      }
    }
    const options = {
      method: 'GET',
    };

    return this.send(url, options);
  }

  /**
   * 不带文件的psot提交
   * @param {string} url 接口地址
   * @param {object} data 要提交的form对象
   */
  httppost(url, data = {}) {
    const params = [];
    for (const k in data) {
      const v = data[k];
      if (tools.strIsEmpty(v)) { continue; }

      params.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
    const token = this.getToken();
    if (token) {
      params.push(`token=${token}`);
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params.join('&'),
    };

    return this.send(url, options);
  }

  /**
   * 带文件的psot提交
   * TODO: 还需配合后端测试
   * @param {string} url 接口地址
   * @param {object} data 要提交的form对象
   */
  httpPostFile(url, data = {}) {
    const form = new FormData();
    for (const k in data) {
      const v = data[k];
      if (tools.strIsEmpty(v)) { continue; }
      form.append(k, data[k]);
    }

    const options = {
      method: 'POST',
      headers: {
        // 'Content-Type': 'multipart/form-data',
      },
      body: form,
    };

    return this.send(url, options);
  }


  getToken() {
    return sessionStorage.getItem('token');
  }

  download(url, params) {
    let parts = [];
    if (typeof params !== 'string') {
      for (const key in params) {
        const value = params[key];
        if (tools.strIsEmpty(value)) {
          continue;
        }
        parts.push(`${key}=${encodeURIComponent(`${value}`)}`);
      }
    } else {
      parts.push(params);
    }
    window.open(`${url}?${parts.join('&')}&token=${this.getToken()}`);
  }
}
