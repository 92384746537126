import React from 'react';
import { renderRoutes } from "react-router-config";
import { Layout, Menu, Empty, Button, Input, Timeline } from 'antd';
import { SwapRightOutlined } from '@ant-design/icons';
import styles from './index.module.css'
import './index.css'
import Copyright from './../../components/CopyRight';
import service from './../../services/shipment';

import loge from './../../assets/logo.jpg';
import { isEmptyObject } from '../../utils/tools';

const { Header, Content, Footer } = Layout;
const { Search } = Input;

export default class Home extends React.Component {

    state = { data: {}, code: 200, msg: '暂无数据' }

    onSearch = async (val) => {
        if (!val) {
            this.setState({ msg: '请输入有效的运单号' });
            return;
        }

        const { data, code, msg = '暂无数据' } = await service.get(val);

        this.setState({ data, code, msg });
    }

    onAuth = () => {
        this.props.history.push('/auth/login');
    }

    render() {
        const { route } = this.props;
        const { data, msg } = this.state;

        return <>
            <Layout>
                <Header style={{ height: '120px', padding: 0 }}>
                    <div className={styles.layout} >
                        <div className={styles.title} style={{ width: `${document.body.clientWidth > 1024 ? 1024 : document.body.clientWidth}px` }}>
                            <img src={loge} alt="logo" className={styles.logoimg} />KDS
                            <div className={styles.loginbtn}>
                                <Button type="link" onClick={this.onAuth}>登录</Button>
                            </div>
                        </div>
                    </div>
                </Header>
                <Content>
                    <Layout>
                        <Header>
                            <div style={{ margin: '0 auto', width: `${document.body.clientWidth > 1024 ? 1024 : document.body.clientWidth}px` }}>
                                <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                                    <Menu.Item key="1">快递查询</Menu.Item>
                                    <Menu.Item key="2">我要寄快递</Menu.Item>
                                </Menu>
                            </div>
                        </Header>
                        <Content style={{ padding: '10px 0', width: `${document.body.clientWidth > 1024 ? 1024 : document.body.clientWidth}px`, margin: '0 auto' }}>
                            <div className="site-layout-content">
                                <Search
                                    placeholder="输入运单号"
                                    allowClear
                                    enterButton="查询"
                                    size="large"
                                    onSearch={this.onSearch}
                                />
                                <div style={{ height: 'calc(100vh - 333px)', overflowY: 'auto', padding: '50px 0', background: 'white' }}>
                                    {
                                        (data == null || isEmptyObject(data)) ? <Empty description={msg} /> : <>
                                            <div className="delivery-brief">
                                                <div className="brief-content clearfix">
                                                    <div className="wrapper_left">
                                                        <div className="bill-num">
                                                            <span className="gray">运单号 </span>
                                                            <span className="number">{data.id}</span>
                                                        </div>
                                                        <div className="locations">{
                                                            [
                                                                data.shipper_address_0,
                                                                data.shipper_address_1,
                                                                data.shipper_address_2,
                                                                data.shipper_address_3
                                                            ].filter(o => !!o).join(',')
                                                        }<SwapRightOutlined style={{ fontSize: '20px', padding: '0 20px' }} />{
                                                            [
                                                                data.receiver_address_0,
                                                                data.receiver_address_1,
                                                                data.receiver_address_2,
                                                                data.receiver_address_3
                                                            ].filter(o => !!o).join(',')
                                                        }</div>
                                                    </div>
                                                    <div className="status-details fr">
                                                        <div className="status-date-time">
                                                            <div className="date">
                                                                <p className="bold fs15">签收时间</p>
                                                                <p className="bold fs15 red">{data.receivetime}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Timeline mode="left">
                                                {
                                                    data.activities.map(item => <Timeline.Item label={item.recordtime} key={item.id}>
                                                        {`${item.activitytext}${!!item.location ? `【${item.location}】` : ''}${!!item.memo ? `【${item.memo}】` : ''}`}
                                                    </Timeline.Item>)
                                                }
                                            </Timeline>
                                        </>
                                    }
                                </div>
                            </div>
                        </Content>
                    </Layout>
                </Content>
                <Footer style={{ textAlign: 'center' }}><Copyright /></Footer>
            </Layout>
            {renderRoutes(route.routes)}
        </>
    }
}