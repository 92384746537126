import React from 'react';
import { Card } from 'antd';

export default class NotFind extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <Card>
          您所查询的页面不存在
        </Card>
      </>
    );
  }
}
