import { message } from 'antd';
import JsonApi from './../utils/jsonapi';
import apiurl from './apiurl';


class Login extends JsonApi {

    /**
     * 验证用户权限
     * @param {object} params
     */
    async auth({ uname, upasswd }) {
        const { data } = await this.httppost(apiurl.login.auth, { uname, upasswd }) || {};
        if (!data) {
            message.error('登陆失败');
            return;
        }

        this.setLoginInfo(data);
        message.success('登陆成功');

        return data;
    }

    async changePw({ oldpwd, newpwd }) {
        const resp = await this.httppost(apiurl.login.modpwd, { oldpwd, newpwd });
        return resp && resp.data;
    }

    /**
     * 判断是否登陆过
     * 已经登陆，返回ture
     */
    checkLoginInfo() {
        const token = sessionStorage.getItem('token');
        return token && token.length > 0;
    }

    setLoginInfo(data) {
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('uname', data.userinfo.uname);
        sessionStorage.setItem('unametext', data.userinfo.unametext);
        sessionStorage.setItem('utype', data.userinfo.utype);
        sessionStorage.setItem('prefix', data.userinfo.prefix || '');
    }

    removeLoginInfo() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('uname');
        sessionStorage.removeItem('unametext');
        sessionStorage.removeItem('utype');
        sessionStorage.removeItem('prefix');
    }

    getUserName() {
        return sessionStorage.getItem('unametext');
    }

    getRole() {
        return parseInt(sessionStorage.getItem('utype'), 10);
    }

    getPrefix() {
        return sessionStorage.getItem('prefix');
    }

    isAdmin() {
        return this.getRole() === 0;
    }

    isNormalUser() {
        return this.getRole() === 2;
    }
}

const loginService = new Login();
export default loginService;
