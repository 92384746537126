import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './index.css';
import reportWebVitals from './reportWebVitals';

import routes from './routes';
import './utils/polyfills';

moment.locale('zh-cn');

const Routers = function () {
  return (
    <BrowserRouter>
      <ConfigProvider locale={zhCN}>
        {renderRoutes(routes)}
      </ConfigProvider>
    </BrowserRouter>
  );
};


ReactDOM.render(<Routers />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
