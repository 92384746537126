import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import Loading from './../../../components/common/Loading';


const AuthLayout = props => {
  const { route } = props;

  return (
    <div className="root">
      <Suspense fallback={<Loading />}>
        {renderRoutes(route.routes)}
      </Suspense>
    </div>
  );
};

AuthLayout.propTypes = {
  route: PropTypes.object
};

export default AuthLayout;
