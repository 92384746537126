
const apiurl = {
    login: {
        auth: '/service/userinfo/login',
        modpwd: '/service/userinfo/modpwd',
    },

    userinfo: {
        list: '/service/userinfo/list',
        find: '/service/userinfo/find',
        save: '/service/userinfo/save',
        edit: '/service/userinfo/edit',
        del: '/service/userinfo/del',
        restore: '/service/userinfo/restore',
        modpw: '/service/userinfo/modpw',
        resetpwd: '/service/userinfo/resetpwd',
    },

    dict: {
        find: '/service/dict/find',
        list: '/service/dict/list',
        save: '/service/dict/save',
        edit: '/service/dict/edit',
        del: '/service/dict/del',
    },

    shipment: {
        get: '/service/shipment/get',
        find: '/service/shipment/find',
        save: '/service/shipment/save',
        edit: '/service/shipment/edit',
        del: '/service/shipment/del',
        receive: '/service/shipment/receive',
        batchreceive: '/service/shipment/batchreceive',
        skpdf: '/service/shipment/skpdf',
        fbapdf: '/service/shipment/fbapdf',
        exportFp: '/service/shipment/exportFp',
        exportKds: '/service/shipment/exportKds',
        exportOcs: '/service/shipment/exportOcs',
        export: '/service/shipment/export',
    },

    activity: {
        find: '/service/activity/find',
        save: '/service/activity/save',
        edit: '/service/activity/edit',
        del: '/service/activity/del',
        manualUpdate: '/service/activity/manualUpdate',
    },
    
    team: {
        find: '/service/team/find',
        list: '/service/team/list',
        save: '/service/team/save',
        edit: '/service/team/edit',
        del: '/service/team/del',
    },
    
    region: {
        list: '/service/region/list',
        listAll: '/service/region/listAll',
    }

};

export default apiurl;
