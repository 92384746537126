import React, { useRef } from 'react';
import { Menu } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import service from './../../../services/login';
import styles from './index.module.css';
import ModPwModal from '../ModPw/ModPwModal';

const { SubMenu } = Menu;


const HeaderUser = () => {

  const history = useHistory();
  const refModPwModal = useRef();

  const logout = () => {
    service.removeLoginInfo();
    history.push('/auth/login');
  }
  
  const changepw = () => {
    refModPwModal.current.show();
  }
  

  return (
    <div className={styles.user_header}>
      <Menu
        className={styles.user_header_menu}
        style={{ float: 'right' }}
        mode="horizontal"
        theme="dark"
      >
        <SubMenu title={(
          <span>
            <UserOutlined />
            <span>{service.getUserName()}</span>
          </span>
        )}
        >
          <Menu.Item key="changepw" onClick={changepw}>
            <LogoutOutlined />
            <span>修改密码</span>
          </Menu.Item>
          <Menu.Item key="logout" onClick={logout}>
            <LogoutOutlined />
            <span>登出</span>
          </Menu.Item>
        </SubMenu>
      </Menu>

      <ModPwModal ref={refModPwModal} onSuccess={logout} />
    </div>
  );
}

export default HeaderUser;
