import React from 'react';
import { Spin } from 'antd';


const STYLE = { position: 'relative', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' };

const Loading = () => {
  return (
    <Spin size="large" tip="页面载入中..." style={STYLE}/>
  )
}

export default Loading;